<template>
    <div class="container" style="background-color: #fff;">
        <h1>{{ $store.state.language.layout.bread_crumbs.survey }}</h1>
        <h5>{{ $store.state.language.inquiry.survey.basic_info }}</h5>
        <hr class="my-1" />
        <div class="row">
            <div
                class="col-lg-4"
                :class="check_form && formData.name == '' ? 'invalid' : ''"
            >
                <label for="fr_name">
                    <span class="red">*</span>
                    {{ $store.state.language.inquiry.survey.name }}
                </label>
                <input
                    type="text"
                    class="form-control"
                    id="fr_name"
                    v-model="formData.name"
                />
                <span class="invalid_info">
                    {{ $store.state.language.inquiry.require_field }}
                </span>
            </div>
            <div
                class="col-lg-4"
                :class="check_form && formData.contact == '' ? 'invalid' : ''"
            >
                <label for="fr_contact">
                    <span class="red">*</span>
                    {{ $store.state.language.inquiry.survey.contact }}
                </label>
                <input
                    type="text"
                    class="form-control"
                    id="fr_contact"
                    v-model="formData.contact"
                />
                <span class="invalid_info">
                    {{ $store.state.language.inquiry.require_field }}
                </span>
            </div>
            <div class="col-lg-4">
                <label for="fr_company">{{
                    $store.state.language.inquiry.survey.company
                }}</label>
                <input
                    type="text"
                    class="form-control"
                    id="fr_company"
                    v-model="formData.company"
                />
            </div>
        </div>
        <div class="mt-4 row">
            <div class="col-lg-6">
                <label class="d-block">
                    1.{{ $store.state.language.inquiry.survey.Q1 }}
                    <span class="red">*</span>
                </label>
                <br />
                <div
                    class="form-check ms-4"
                    style="display: inline-block;"
                    v-for="n in 5"
                    :key="n"
                >
                    <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        v-model="formData.Q1"
                        :id="`Q1_${n}`"
                    />
                    <label class="form-check-label" :for="`Q1_${n}`">
                        {{ $store.state.language.inquiry.survey[`A1-${n}`] }}
                    </label>
                </div>
            </div>
            <div class="col-lg-6">
                <label class="d-block">
                    2.{{ $store.state.language.inquiry.survey.Q2 }}
                    <span class="red">*</span>
                </label>
                <br />
                <div
                    class="form-check ms-4"
                    style="display: inline-block;"
                    v-for="n in 5"
                    :key="n"
                >
                    <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        v-model="formData.Q2"
                        :id="`Q2_${n}`"
                    />
                    <label class="form-check-label" :for="`Q2_${n}`">
                        {{ $store.state.language.inquiry.survey[`A2-${n}`] }}
                    </label>
                </div>
            </div>
            <h5>{{ $store.state.language.inquiry.survey.ux }}</h5>
            <hr class="my-1" />
            <div class="row">
                <div class="col-lg-6">
                    <label class="d-block">
                        3.{{ $store.state.language.inquiry.survey.Q3 }}
                        <span class="red">*</span>
                    </label>
                    <div
                        class="form-check ms-4"
                        style="display: inline-block;"
                        v-for="n in 5"
                        :key="n"
                    >
                        <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            v-model="formData.Q3"
                            :id="`Q3_${n}`"
                        />
                        <label class="form-check-label" :for="`Q3_${n}`">
                            {{
                                $store.state.language.inquiry.survey[`A3-${n}`]
                            }}
                        </label>
                    </div>
                </div>
                <div class="col-lg-6">
                    <label class="d-block">
                        4.{{ $store.state.language.inquiry.survey.Q4 }}
                        <span class="red">*</span>
                    </label>
                    <div
                        class="form-check ms-4"
                        style="display: inline-block;"
                        v-for="n in 2"
                        :key="n"
                    >
                        <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            v-model="formData.Q4"
                            :id="`Q4_${n}`"
                        />
                        <label class="form-check-label" :for="`Q4_${n}`">
                            {{
                                $store.state.language.inquiry.survey[`A4-${n}`]
                            }}
                        </label>
                    </div>
                </div>
                <div class="col-lg-6">
                    <label>
                        5.{{ $store.state.language.inquiry.survey.Q5 }}
                        <span class="red">*</span>
                    </label>
                    <textarea
                        class="form-control"
                        style="width: 100%;"
                        v-model="formData.Q5"
                    ></textarea>
                </div>
            </div>
            <h5 class="mt-2">
                {{ $store.state.language.inquiry.survey.suggest }}
            </h5>
            <hr class="my-1" />
            <div class="row">
                <div class="col-lg-6">
                    <label>
                        6.{{ $store.state.language.inquiry.survey.Q6 }}
                        <span class="red">*</span>
                    </label>
                    <textarea
                        class="form-control"
                        style="width: 100%;"
                        v-model="formData.Q6"
                    ></textarea>
                </div>
                <div class="col-lg-6">
                    <label class="d-block">
                        7.{{ $store.state.language.inquiry.survey.Q7 }}
                        <span class="red">*</span>
                    </label>
                    <div
                        class="form-check ms-4"
                        style="display: inline-block;"
                        v-for="n in 2"
                        :key="n"
                    >
                        <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            v-model="formData.Q7"
                            :id="`Q7_${n}`"
                        />
                        <label class="form-check-label" :for="`Q7_${n}`">
                            {{
                                $store.state.language.inquiry.survey[`A7-${n}`]
                            }}
                        </label>
                    </div>
                </div>
                <div class="col-lg-6">
                    <label>
                        8.{{ $store.state.language.inquiry.survey.Q8 }}
                        <span class="red">*</span>
                    </label>
                    <textarea
                        class="form-control"
                        style="width: 100%;"
                        v-model="formData.Q8"
                    ></textarea>
                </div>
            </div>
            <div class="col-12 text-center mt-2 mb-5">
                <p>{{ $store.state.language.inquiry.survey.thanks }}</p>
                <button
                    class="btn btn-primary btn-lg"
                    v-html="$store.state.language.inquiry.submit"
                    @click="send_inquiry_form()"
                ></button>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
.red {
    color: #f00;
}

.invalid_info,
.valid_info {
    display: none;
}

.invalid {
    input {
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .invalid_info {
        color: #dc3545;
        display: block;
    }
}
</style>
<script>
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, {
    siteKey: "6LfHnYAeAAAAAG763J4o-g4G3BntOmYdW4--CzYm",
    loaderOptions: {
        useRecaptchaNet: true
    }
});

export default {
    data() {
        return {
            formData: {
                source: "",
                name: "",
                contact: "",
                company: "",
                Q1: "",
                Q2: "",
                Q3: "",
                Q4: "",
                Q5: "",
                Q6: "",
                Q7: "",
                Q8: ""
            },
            token: "",
            check_form: false
        };
    },
    mounted() {
        this.recaptcha();
        setInterval(this.recaptcha, 110000);
        this.formData.source = this.$store.state.lan;
    },
    computed: {
        valiad_form() {
            let output = true;
            switch (true) {
                case this.token == "":
                case this.name == "":
                case this.contact == "":
                case this.company == "":
                case this.Q1 == "":
                case this.Q2 == "":
                case this.Q3 == "":
                case this.Q4 == "":
                case this.Q5 == "":
                case this.Q6 == "":
                case this.Q7 == "":
                case this.Q8 == "":
                case this.valiad_mail == null:
                    output = false;
                    break;
            }
            return output;
        },
        valiad_mail() {
            return String(this.formData.contact_email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        }
    },
    methods: {
        async recaptcha() {
            await this.$recaptchaLoaded();
            // Execute reCAPTCHA with action "login".
            this.token = await this.$recaptcha("login");
        },
        send_inquiry_form() {
            let vue = this;
            vue.check_form = true;
            if (vue.valiad_form) {
                const data = new FormData();
                for (let k in vue.formData) {
                    switch (k) {
                        default:
                            data.append(k, vue.formData[k]);
                            break;
                    }
                }
                data.append("token", vue.token);
                vue.$swal.fire({
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    html: `
                        <div style="height:5em">
                            <i class="fas fa-circle-notch fa-spin fa-4x" style="color: #42a5f5;"></i>
                        </div>`,
                    showCancelButton: false,
                    showCloseButton: false,
                    showConfirmButton: false,
                    title: "處理中",
                    width: "12rem",
                    didOpen() {
                        vue.axios
                            .post(`/inquiry_save/survey.php`, data)
                            .then(res => {
                                if (res.data.status != "ok") {
                                    console.log(res);
                                    vue.$swal.fire({
                                        icon: "error",
                                        title: res.data.msg || "Error!"
                                    });
                                    vue.recaptcha();
                                } else {
                                    // 清空
                                    document.cookie =
                                        "inquiry=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
                                    vue.$swal.fire({
                                        icon: "success",
                                        title:
                                            vue.$store.state.language.inquiry
                                                .finish,
                                        showConfirmButton: false,
                                        allowEscapeKey: false,
                                        allowOutsideClick: false,
                                        didOpen() {
                                            setTimeout(function() {
                                                location.reload();
                                            }, 1500);
                                        }
                                    });
                                }
                            });
                    }
                });
            }
        }
    }
};
</script>
